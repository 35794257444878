import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ArticleCell from '../components/article-cell'
import Pager from '../components/pager'
import Container from '../components/container'
import { writerLinkUrl } from '../components/writer-link'
import ScrollTop from '../components/scroll-top'
import Ad from '../components/ad'

export default function writer ({ data, pageContext }) {
  const { totalPages, currentPage, author } = pageContext
  const { edges: posts } = data.allMarkdownRemark

  return (
    <Layout fab={<ScrollTop />}>
      <Container>
        <Helmet
          title={`${author}の記事一覧 | ${data.site.siteMetadata.title}`}
          meta={[
            {
              name: 'description',
              content: `${author}の記事一覧 | ${data.site.siteMetadata.description}`
            },
          ]}
        />
        <main>
          <h1 className="page-title page-title--writer">{author}</h1>
          <ol className="article-list">
            {
              posts
                .filter(post => post.node.frontmatter.title.length > 0)
                .map(({ node: post }) => {
                  return (
                    <li className="article-list__item" key={post.frontmatter.slug}>
                      <ArticleCell
                        title={post.frontmatter.title}
                        href={`/${post.frontmatter.slug}`}
                        date={post.frontmatter.date}
                        author={post.frontmatter.author}
                        eyeCatch={post.frontmatter.eyeCatch}
                        tags={post.frontmatter.tags}
                      />
                    </li>
                  )
                })
            }
          </ol>
          <Pager
            base={writerLinkUrl(author)}
            current={currentPage}
            total={totalPages}
          />
        </main>
        {process.env.NODE_ENV !== 'development' && (
          <aside>
            <Ad
              client={data.site.siteMetadata.adsenseClientId}
              slot="1426003445"
              type="landscape"
            />
          </aside>
        )}
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query writerPage($public: [Boolean]!, $skip: Int!, $limit: Int!, $author: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: {
          public: { in: $public }
          author: { eq: $author }
        }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "YYYY.MM.DD")
            slug
            author
            eyeCatch
            tags
          }
        }
      }
    }
    site {
      siteMetadata {
        url
        title
        description
        adsenseClientId
      }
    }
  }
`
